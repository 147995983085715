<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('races.items')" />

      <main-table
        :fields="fields"
        :list_url="'admin/race'"
        :reloadData="reloadData"
        :createBtnLabel="$t('races.create')"
        createRouteName="events.raceCreate"
        :excelFields="excelFields"
        excelName="races"
        :downloadSampleStatus="true"
        downloadSampleUrl="race/excel-sample"
        :uploadFileStatus="true"
        uploadFileUrl="race/excel"
      />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import searchMixin from "../../../../Mixins/searchMixin";

export default {
  mixins: [searchMixin],
  data() {
    return {
      fields: [
        this.$t("global.selected"),
        { key: "id", label: this.$t("table.id"), sortable: true },
        { key: "name", label: this.$t("table.name"), sortable: true },
        {
          key: "competitionName",
          label: this.$t("competitions.item"),
          sortable: true,
        },
        { key: "trackName", label: this.$t("tracks.item"), sortable: true },
        { key: "distance", label: this.$t("global.distance"), sortable: true },
        {
          key: "start_time",
          label: this.$t("races.startTime"),
          type: "dateTime",
          sortable: true,
        },
        { key: "prize", label: this.$t("global.prize"), sortable: true },
        {
          key: "start_prediction_time",
          label: this.$t("races.startPredictionTime"),
          type: "dateTime",
          sortable: true,
        },
        {
          key: "end_prediction_time",
          label: this.$t("races.endPredictionTime"),
          type: "dateTime",
          sortable: true,
        },
        { key: "status", label: this.$t("global.status"), sortable: true },
        {
          key: "created_at",
          label: this.$t("table.createdAt"),
          type: "dateTime",
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          type: "actions",
          actions: [
            {
              text: this.$t("global.view"),
              icon: "fas fa-eye",
              color: "info",
              ifNavigate: true,
              routeName: "events.raceView",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.update"),
              icon: "fas fa-pen",
              color: "primary",
              ifNavigate: true,
              routeName: "events.raceUpdate",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.delete"),
              icon: "fas fa-trash-alt",
              color: "danger",
              showAlert: true,
              actionHeader: this.$t("global.delete"),
              titleHeader: this.$t("races.item"),
              textContent: "name",
              url: `admin/race`,
              type: "delete",
            },
            {
              showIf: (i) => i.status === "finished",
              text: this.$t("races.updateRaceResult"),
              icon: "fas fa-user-edit",
              color: "primary",
              ifNavigate: true,
              routeName: "events.updateRaceResult",
              routeParams: ["id"],
            },
          ],
        },
      ],
      excelFields: {
        [this.$t("table.id")]: "id",
        [this.$t("table.name")]: "name",
        [this.$t("competitions.item")]: "competitionName",
        [this.$t("tracks.item")]: "trackName",
        [this.$t("global.distance")]: "distance",
        [this.$t("races.startTime")]: "start_time",
        [this.$t("global.prize")]: "prize",
        [this.$t("races.startPredictionTime")]: "start_prediction_time",
        [this.$t("races.endPredictionTime")]: "naend_prediction_timeme",
        [this.$t("global.status")]: "status",
        [this.$t("table.createdAt")]: "created_at",
      },
      field: [
        this.$t("global.selected"),
        { key: "id", label: this.$t("table.id"), sortable: true },
        { key: "name", label: this.$t("table.name"), sortable: true },
        {
          key: "competitionName",
          label: this.$t("competitions.item"),
          sortable: true,
        },
        { key: "trackName", label: this.$t("tracks.item"), sortable: true },
        { key: "distance", label: this.$t("global.distance"), sortable: true },
        {
          key: "start_time",
          label: this.$t("races.startTime"),
          type: "dateTime",
          sortable: true,
        },
        { key: "prize", label: this.$t("global.prize"), sortable: true },
        {
          key: "start_prediction_time",
          label: this.$t("races.startPredictionTime"),
          type: "dateTime",
          sortable: true,
        },
        {
          key: "end_prediction_time",
          label: this.$t("races.endPredictionTime"),
          type: "dateTime",
          sortable: true,
        },
        { key: "status", label: this.$t("global.status"), sortable: true },
        {
          key: "created_at",
          label: this.$t("table.createdAt"),
          type: "dateTime",
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          type: "actions",
          actions: [
            {
              text: this.$t("global.view"),
              icon: "fas fa-eye",
              color: "info",
              ifNavigate: true,
              routeName: "events.raceView",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.update"),
              icon: "fas fa-pen",
              color: "primary",
              ifNavigate: true,
              routeName: "events.raceUpdate",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.delete"),
              icon: "fas fa-trash-alt",
              color: "danger",
              showAlert: true,
              actionHeader: this.$t("global.delete"),
              titleHeader: this.$t("races.item"),
              textContent: "name",
              url: "admin/race",
              type: "delete",
            },
            {
              showIf: (i) => i.status === "finished",
              text: this.$t("races.updateRaceResult"),
              icon: "fas fa-user-edit",
              color: "primary",
              ifNavigate: true,
              routeName: "events.updateRaceResult",
              routeParams: ["id"],
            },
          ],
        },
      ],
    };
  },
  props: {
    trackId: { default: "" },
    competitionId: { default: "" },
  },
  mounted() {
    core.index();
  },
  created() {
    this.filter.track_id = this.trackId;
    this.filter.competition_id = this.competitionId;
  },
};
</script>
